import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import OrnamentPageBottom from '../assets/images/flower-vintage-3.png';
import Countdown from "../components/Countdown";
import { initScrollReveal } from "../components/ScrollReveal";

const Rsvp = () => {
  const [showAlert, setShowAlert] = React.useState(false);
  const [error, setError] = React.useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [present, setPresent] = useState(false);
  const navigate = useNavigate();

  const mutationQuery = `
    mutation {
      createMessage(data: { name: "${name}", message: "${message}", present: ${present} }) {
        id
      }
    }
  `;

  const listQuery = `
  {
    messages(stage: DRAFT, first: 100){
      name
      message
      present
      createdAt
    }
  }
  `;

  const saveGuest = async (e) => {
    e.preventDefault();

    if (!name.trim() || !message.trim()) {
      setError("Silahkan isi nama dan ucapan terlebih dahulu");
      setTimeout(() => {
        setError(""); 
        setName("");
        setMessage("");
      }, 3000);
      return;
    }

    // try {
    //   await axios.post("http://localhost:5000/guests", {
    //     name,
    //     message,
    //     present,
    //   });
    //   setShowAlert(true);
    //   setTimeout(() => {
    //     setShowAlert(false);
    //   }, 3000);
    // } catch (error) {
    //   if (error.message === "Network Error") {
    //     // Handle the case when the server is not running
    //     setError("Server tidak berjalan. Silakan coba lagi nanti.");
    //   } else {
    //     setError("Terjadi kesalahan. Silakan coba lagi.");
    //   }
  
    //   // Clear the error message after 3 seconds
    //   setTimeout(() => {
    //     setError("");
    //   }, 3000);
    //   console.log("Error details:", error);
    // }

    const res = await fetch(
      'https://ap-south-1.cdn.hygraph.com/content/cm3o6oumf01si07uvvbdloqts/master',
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization:
            'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE3MzIwOTgyODksImF1ZCI6WyJodHRwczovL2FwaS1hcC1zb3V0aC0xLmh5Z3JhcGguY29tL3YyL2NtM282b3VtZjAxc2kwN3V2dmJkbG9xdHMvbWFzdGVyIiwibWFuYWdlbWVudC1uZXh0LmdyYXBoY21zLmNvbSJdLCJpc3MiOiJodHRwczovL21hbmFnZW1lbnQtYXAtc291dGgtMS5oeWdyYXBoLmNvbS8iLCJzdWIiOiJhZjQ5ZmQ5Mi03ZDk1LTQ5NGYtOTAwMy04OTIwNzNiZmIyOTYiLCJqdGkiOiJjbTNwcWxsb2EwN25kMDdwZTN4c2o3cTlmIn0.MLKPEBg9MercU3JyA-ZyvidfTjATgjHi2l-FMjNbOMjAXgCBm3QoIxrfmcFudyiwArrEkvfJV_vTGT-z_exqauiHw5fMHSCyhioDEKGWg8OZF0-uPKRfM-tsCspAkCLdfC4fFQWxKBR_KC2IJdn2Am3DKKdT9gwDj4EFinSGT_MIpLb86gEKZanBGSyVAb1Tp1vj3l3oAz5xP7PCY3DQjhnZ9JA56k9CPraz4EXXqW1_qrYbQUyJ4teSg3ElAVAWCf3gRfwPq_EF-LN59m7LzjvArjCrV8G8UzV2uS48Qnc5v6VuICJ1vmeuqK8nhzBEl1TDJxQwxLFN5RaqXm1zd5wkXlXgcKRB9S9ICncNedtR2v-KtZMfv4sjFZ7e8iC2wclzQJbZqM5ZLQTZNTeKvauCHMm_SphPEouUzK4a4Ktct2ZVlOIxefO011Pb7zrTA36wm7ZEa_wGNUVofUf2g5VdYOsBaYU6VxvielfGN9yq0wS0IiN14YziGhtSYaO-DnrlVeg2ajm15eO3lC9yEZh2YaTk30U7QRqD4X7eAdK5ThRwbD-tedpzvQQC66GP8dyxLOz2Dy0Au3rY0WbwbwWkgy8LsWD3uYwWvzmCSSEWkYVYCp5OYWf9PJnbpUQ9wnqnqi_22s4yDq6SEwOA1HgI2frpmeYYCSGyP99xhlg'
        },
        method: 'POST',
        body: JSON.stringify({ query: mutationQuery })
      }
    );
  
    if (res.status === 200) {
      setShowAlert(true);
      setTimeout(async () => {
        await getGuests();
        setName("");
        setMessage("");
      }, 1000);
    }
  };
  
  const [guests, setGuest] = useState([]);

  useEffect(() => {
    getGuests();
    initScrollReveal();
  }, []);

  const getGuests = async () => {
  
    const list = await fetch(
      'https://ap-south-1.cdn.hygraph.com/content/cm3o6oumf01si07uvvbdloqts/master',
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization:
            'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE3MzIwOTgyODksImF1ZCI6WyJodHRwczovL2FwaS1hcC1zb3V0aC0xLmh5Z3JhcGguY29tL3YyL2NtM282b3VtZjAxc2kwN3V2dmJkbG9xdHMvbWFzdGVyIiwibWFuYWdlbWVudC1uZXh0LmdyYXBoY21zLmNvbSJdLCJpc3MiOiJodHRwczovL21hbmFnZW1lbnQtYXAtc291dGgtMS5oeWdyYXBoLmNvbS8iLCJzdWIiOiJhZjQ5ZmQ5Mi03ZDk1LTQ5NGYtOTAwMy04OTIwNzNiZmIyOTYiLCJqdGkiOiJjbTNwcWxsb2EwN25kMDdwZTN4c2o3cTlmIn0.MLKPEBg9MercU3JyA-ZyvidfTjATgjHi2l-FMjNbOMjAXgCBm3QoIxrfmcFudyiwArrEkvfJV_vTGT-z_exqauiHw5fMHSCyhioDEKGWg8OZF0-uPKRfM-tsCspAkCLdfC4fFQWxKBR_KC2IJdn2Am3DKKdT9gwDj4EFinSGT_MIpLb86gEKZanBGSyVAb1Tp1vj3l3oAz5xP7PCY3DQjhnZ9JA56k9CPraz4EXXqW1_qrYbQUyJ4teSg3ElAVAWCf3gRfwPq_EF-LN59m7LzjvArjCrV8G8UzV2uS48Qnc5v6VuICJ1vmeuqK8nhzBEl1TDJxQwxLFN5RaqXm1zd5wkXlXgcKRB9S9ICncNedtR2v-KtZMfv4sjFZ7e8iC2wclzQJbZqM5ZLQTZNTeKvauCHMm_SphPEouUzK4a4Ktct2ZVlOIxefO011Pb7zrTA36wm7ZEa_wGNUVofUf2g5VdYOsBaYU6VxvielfGN9yq0wS0IiN14YziGhtSYaO-DnrlVeg2ajm15eO3lC9yEZh2YaTk30U7QRqD4X7eAdK5ThRwbD-tedpzvQQC66GP8dyxLOz2Dy0Au3rY0WbwbwWkgy8LsWD3uYwWvzmCSSEWkYVYCp5OYWf9PJnbpUQ9wnqnqi_22s4yDq6SEwOA1HgI2frpmeYYCSGyP99xhlg'
        },
        method: 'POST',
        body: JSON.stringify({ query: listQuery })
      }
    );

    // const response = await axios.get("http://localhost:5000/guests");
    const resJson = await list.json();
    setGuest(resJson.data.messages);
  };

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentGuests = guests.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatDateTime = (datetime) => {
    const date = new Date(datetime);
    return date.toLocaleString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    });
  };
  // End Pagination

  return (
    <div className="min-h-screen">
      {/* <img src={OrnamentPageBottom}  className="r-ornament-bottom absolute bottom-0 z-10"/> */}

      <div className="text-center pt-8 reveal-zoom-in">
        <h1>Hari Bahagia Kami - 14 Desember 2024</h1>
        <Countdown targetDate="2024-12-14T13:00:00" />
      </div>
      
      <div className="flex justify-center items-center">

        <div className="container s-background rsvp">
          <h2 className="title reveal-left">Ucapan dan Doa Restu</h2>

          <div className="w-full reveal-zoom-in">
            {showAlert && (
              <div className="termikasih text-center zoom-in">
                <div className="p-2 bg-green-500 items-center text-indigo-100 leading-none flex lg:inline-flex mb-2 rounded" role="alert">
                  <span className="flex bg-green-700 uppercase px-2 py-1 text-xs font-bold mr-3">Terkirim</span>
                  <span className="mr-2 text-left flex-auto">Terima kasih atas ucapan dan doa nya.</span>
                </div>
              </div>
            )}

            {error && (
              <div className="termikasih text-center">
                <div
                  className="p-2 bg-red-500 items-center text-indigo-100 leading-none flex lg:inline-flex mb-2 rounded"
                  role="alert"
                >
                  <span className="flex bg-red-700 uppercase px-2 py-1 text-xs font-bold mr-3">
                    Error
                  </span>
                  <span className="mr-2 text-left flex-auto">{error}</span>
                </div>
              </div>
            )}

            <form onSubmit={saveGuest}>
              <div className="mb-4">
                <input
                  type="text"
                  className="input text-xs shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Nama"
                />
                <textarea
                  className="shadow text-xs appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Ucapan"
                />

                <div className="mb-4">
                  <p className="text-gray-700 text-sm mb-2">Kehadiran:</p>
                  <div className="flex items-center gap-2">
                    <button
                      type="button"
                      className={`attendance-button text-xs ${present === true ? 'active' : ''}`}
                      onClick={() => setPresent(true)}
                    >
                      Hadir
                    </button>
                    <button
                      type="button"
                      className={`attendance-button text-xs ${present === false ? 'active' : ''}`}
                      onClick={() => setPresent(false)}
                    >
                      Tidak Hadir
                    </button>
                  </div>
                  {/* Hidden inputs to store the selected attendance value */}
                  <input
                    type="radio"
                    name="kehadiran"
                    id="hadir"
                    value="Hadir"
                    className="hidden"
                    checked={present === true}
                    readOnly
                  />
                  <input
                    type="radio"
                    name="kehadiran"
                    id="tidak-hadir"
                    value="Tidak Hadir"
                    className="hidden"
                    checked={present === false}
                    readOnly
                  />
                </div>
              </div>
              <div className="flex items-center justify-between">
                <button
                  className="btn-kirim text-xs w-full text-white font-bold py-2 px-4 rounded"
                  type="submit"
                >
                  Kirim
                </button>
              </div>
            </form>

            <hr className="border-solid border border-red-900 m-5"/>

            <div className="rsvp-message-wrapper">
              {currentGuests.map((guest, index) => (
                <div className="rsvp-message mb-2" key={index}>
                  <div className="flex items-center">
                    <span className="name mr-1">{guest.name}</span>
                    {guest.present === true ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="size-5"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.403 12.652a3 3 0 0 0 0-5.304 3 3 0 0 0-3.75-3.751 3 3 0 0 0-5.305 0 3 3 0 0 0-3.751 3.75 3 3 0 0 0 0 5.305 3 3 0 0 0 3.75 3.751 3 3 0 0 0 5.305 0 3 3 0 0 0 3.751-3.75Zm-2.546-4.46a.75.75 0 0 0-1.214-.883l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="size-5"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM8.28 7.22a.75.75 0 0 0-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 1 0 1.06 1.06L10 11.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L11.06 10l1.72-1.72a.75.75 0 0 0-1.06-1.06L10 8.94 8.28 7.22Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    )}
                  </div>
                  <p className="time">{formatDateTime(guest.createdAt)}</p>
                  <p className="message">{guest.message}</p>
                </div>
              ))}

              {/* Pagination */}
              <div className="pagination">
                {Array.from({ length: Math.ceil(guests.length / itemsPerPage) }, (_, i) => (
                  <button
                    key={i + 1}
                    className={`page-link ${currentPage === i + 1 ? "active" : ""}`}
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                ))}
              </div>

            </div>
          </div>
        </div>

      </div>

    </div>
  );
}

export default Rsvp;
