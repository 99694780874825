import React, { useEffect, useState } from "react";

const Countdown = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = new Date(targetDate).getTime() - new Date().getTime();

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return { days, hours, minutes, seconds };
    } else {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  return (
    <div className="countdown">
      <div className="countdown-item-wrap">
        <div>{timeLeft.days}</div>
        <div> Hari </div>
      </div>
      <div className="countdown-item-wrap">
        <div>{timeLeft.hours}</div>
        <div> Jam </div>
      </div>
      <div className="countdown-item-wrap">
        <div>{timeLeft.minutes}</div>
        <div> Menit </div>
      </div>
      <div className="countdown-item-wrap">
        <div>{timeLeft.seconds}</div>
        <div> Detik </div>
      </div>
    </div>
  );
};

export default Countdown;
