import {BrowserRouter, Routes, Route} from "react-router-dom";
import GuestList from "./components/GuestList";
import Login from "./components/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import './App.css';

import Template1 from './templates/Template1'
import Template2 from './templates/Template2'
import Template3 from './templates/Template3'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/ninin-invite/" element={<Template3/>}/>
          <Route path="/allan-invite/" element={<Template2/>}/>
          <Route path="/login" element={<Login />} />
          <Route
            path="/guests"
            element={
              <ProtectedRoute>
                <GuestList />
              </ProtectedRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
