import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const GuestList = () => {
  const [guests, setGuest] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getGuests();
    console.log("LOCAL STORAGE", localStorage);
  }, []);

  const getGuests = async () => {
    const response = await axios.get("http://localhost:5000/guests");
    setGuest(response.data);
  };

  const deleteGuest = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this guest?");
    if (!confirmDelete) return;

    try {
      await axios.delete(`http://localhost:5000/guests/${id}`);
      getGuests();
    } catch (error) {
      console.log(error);
    }
  };

  return (
  <div className="min-h-screen flex justify-center">
    <div className="w-1/2 mt-8">
      {/* <Link to="add" className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600">
        Add New
      </Link> */}
      <Link 
        to="/login" 
        onClick={() => {
          localStorage.removeItem("isAuthenticated");
        }}
        className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 ml-4"
      >
        Logout
      </Link>
      <table className="min-w-full mt-4 bg-white shadow-md rounded-lg">
        <thead>
          <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
            <th className="py-3 px-6 text-left">No</th>
            <th className="py-3 px-6 text-left">Name</th>
            <th className="py-3 px-6 text-left">Message</th>
            <th className="py-3 px-6 text-left">Present</th>
            <th className="py-3 px-6 text-left">Actions</th>
          </tr>
        </thead>
        <tbody className="text-gray-700 text-sm font-light">
          {guests.map((guest, index) => (
            <tr key={guest.id} className="border-b border-gray-200 hover:bg-gray-100">
              <td className="py-3 px-6 text-left whitespace-nowrap">{index + 1}</td>
              <td className="py-3 px-6 text-left">{guest.name}</td>
              <td className="py-3 px-6 text-left">{guest.message}</td>
              <td className="py-3 px-6 text-left">{guest.present}</td>
              <td className="py-3 px-6 text-left">
                {/* <Link
                  to={`edit/${guest.id}`}
                  className="bg-blue-500 text-white px-2 py-1 rounded-md text-sm mr-2 hover:bg-blue-600"
                >
                  Edit
                </Link> */}
                <button
                  onClick={() => deleteGuest(guest.id)}
                  className="bg-red-500 text-white px-2 py-1 rounded-md text-sm hover:bg-red-600"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>

  );
};

export default GuestList;