import React, {useEffect} from 'react';
import FrameWithText from '../assets/images/frame-with-text.png';
import OrnamentTopFrame from '../assets/images/flower-vintage-6.png';
import OrnamentBottomFrame from '../assets/images/flower-vintage-8.png';
import OrnamentPageBottom from '../assets/images/flower-vintage-5.png';
import Assalam from '../assets/images/assalam.svg';
import Muslim from '../assets/images/muslim.png';
import Muslimah from '../assets/images/muslimah.png';
import { initScrollReveal } from "../components/ScrollReveal";

const Speech = () => {
  useEffect(() => {
    initScrollReveal();
  }, []);

  return (
    <div className="relative min-h-screen flex justify-center">
      <img src={OrnamentPageBottom}  className="w-ornament-bottom absolute bottom-0 z-10 reveal-bottom"/>
      <div className="container s-background">
          <div className="s-frame-wrap relative top-10 reveal-top">
            <img src={OrnamentTopFrame} alt="OrnamentTopFrame" className="s-ornament-top"/>
            <img src={FrameWithText} alt="FrameWithText"/>
            <img src={OrnamentBottomFrame} alt="OrnamentBottomFrame" className="s-ornament-bottom"/>
          </div>
         
          {/* <div className="s-top">
            <p className="s-text">
              "Dan diantara tanda-tanda kekuasaan-Nya ialah diciptakan-Nya untukmu pasangan hidup dari jenismu sendiri supaya kamu mendapat ketenangan  hati dan dijadikan-Nya kasih sayang diantara kamu. Sesungguhnya yang demikian menjadi tanda-tanda kebesaran-Nya bagi orang-orang yang berpikir."
            </p>
            <p className="s-text">QS. Ar-rum: 21</p>
          </div> */}

          <img src={Assalam} alt="Assalam" className="s-assalam reveal-bottom"/>
          <p className="s-text reveal-zoom-in">
          Dengan memohon rahmat dan ridho Allah Subhanahu Wa Ta'ala, Insyaa Allah kami akan menyelenggarakan acara pernikahan:
          </p>

          <div className="s-bottom">
            <div className="s-side reveal-left">
              <img src={Muslim} alt="Muslim"/>
              <h3>Muhammad Dahlan</h3>
              <h5>Putra dari Bpk (alm) H. M. Zaini Ichsan</h5>
              <h5>dan Ibu Hj. Malichah</h5>
            </div>
            <div className="s-side reveal-right">
              <img src={Muslimah} alt="Muslimah"/>
              <h3>Fitrotul Aini Zamhariri</h3>
              <h5>Putra dari Bpk (alm) H. Moh. Luqman</h5>
              <h5>dan Ibu Hj. Susila</h5>
            </div>
          </div>
      </div>
    </div>
  );
}

export default Speech;
