// constants/images.js
import FlowerVintage1 from '../assets/images/flower-vintage-1.png';
import FlowerVintage3 from '../assets/images/flower-vintage-3.png';
import InvitationIcon from '../assets/images/invitation.svg';
import Shape from '../assets/images/frame-shape.svg';
import Couple from '../assets/images/muslim-couple-2.png';
import FlowerVintage4 from '../assets/images/flower-vintage-4.png';
import FlowerVintage5 from '../assets/images/flower-vintage-5.png';
import Frame1 from '../assets/images/Frame-1.png';
import FrameWithText from '../assets/images/frame-with-text.png';
import FlowerVintage6 from '../assets/images/flower-vintage-6.png';
import FlowerVintage8 from '../assets/images/flower-vintage-8.png';
import Assalam from '../assets/images/assalam.svg';
import Muslim from '../assets/images/muslim.png';
import Muslimah from '../assets/images/muslimah.png';

export const imageAssets = [
  FlowerVintage1,
  FlowerVintage3,
  FlowerVintage4,
  FlowerVintage5,
  FlowerVintage6,
  FlowerVintage8,
  InvitationIcon,
  Couple,
  Shape,
  Frame1,
  FrameWithText,
  Assalam,
  Muslim,
  Muslimah
];
