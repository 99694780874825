import React, { useRef, useEffect, useState } from 'react';
import Shape from '../assets/images/frame-shape.svg';
import Couple from '../assets/images/muslim-couple-2.png';
import OrnamentShape from '../assets/images/flower-vintage-4.png';
import OrnamentPageBottom from '../assets/images/flower-vintage-5.png';

const InvitationDate = () => {
  
  return (
    <div className="relative min-h-screen flex flex-col items-center justify-center">
      <img src={OrnamentPageBottom}  className="w-ornament-bottom absolute bottom-0 z-10"/>
      <div
        className={`container i-background`}
      >
          <img src={Shape} alt="Shape" className=""/>
          <img src={OrnamentShape} alt="OrnamentShape" className="i-ornament"/>
          <div className="i-centered">
            <h5 className="i-invited">YOU ARE INVITED TO THE WEDDING OF</h5>
            <h2 className="i-name pt-2">Fitrotul Aini Zamhariri</h2>
            <h2 className="i-and">And</h2>
            <h2 className="i-name pb-2">Muhammad Dahlan</h2>

            <h2 className="i-bulan">DESEMBER</h2>
            <div className="i-tanggal-wrap">
              <div className="i-hari">HARI SABTU</div>
              <div className="i-tanggal">14</div>
              <div className="i-jam">13:00 - 15:00 WIB</div>
            </div>
            <h2 className="i-tahun">2024</h2>          
          </div>
          <img src={Couple} alt="Couple" className="i-couple"/>
      </div>
    </div>
  );
}

export default InvitationDate;
