import ScrollReveal from "scrollreveal";

export const initScrollReveal = () => {
  ScrollReveal().reveal(".reveal-bottom", {
    origin: "bottom",
    distance: "50px",
    duration: 2000,
    reset: false,
  });

  ScrollReveal().reveal(".reveal-top", {
    origin: "top",
    distance: "50px",
    duration: 2000,
    reset: false,
  });

  ScrollReveal().reveal(".reveal-right", {
    origin: "right",
    distance: "50px",
    duration: 2000,
    reset: false,
  });

  ScrollReveal().reveal(".reveal-left", {
    origin: "left",
    distance: "50px",
    duration: 2000,
    reset: false,
  });

  ScrollReveal().reveal(".reveal-zoom-in", {
    scale: 0.5,
    duration: 1500,
    reset: false,
  });

};
