import React from 'react';
import FrameImg from '../assets/images/muslim-couple.png';
import FrameOrnament from '../assets/images/flower-vintage-2.png';

const Frame = () => {

  return (
    <div>
      <div className="relative zoom-in">
        <img src={FrameImg} />
        <label className="w-frame-name absolute left-0 right-0">
          Ninin & Allan
        </label>
        <label className="w-frame-date absolute left-0 right-0">
          14 DESEMBER 2024
        </label>
        <div className="w-frame-ornament">
          <img src={FrameOrnament} />
        </div>
      </div>
    </div>
  );  
};

export default Frame;
