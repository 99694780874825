import React from 'react';
import Frame1 from '../assets/images/Frame-1.png';

const Event2 = () => {
  return (
    <div className="min-h-screen flex justify-center items-center">
      <div className="container s-background">
          <div className="e-frame-wrap relative reveal-left">
            <img src={Frame1} alt="Frame1" className=""/>
            <div className="e-text">
              <h2>Akad Nikah</h2>
              <h6>JUMAT</h6>
              <h4>13 DESEMBER</h4>
              <h6>2024</h6>
              <h6>Pukul 07.00 WIB</h6>

              <hr />

              <h6>Rumah Bpk (alm) H. M. Luqman</h6>
              <p>Pesanggrahan Gg. Tj. No. 53</p>
            </div>
          </div>
         
          <div className="e-frame-wrap relative reveal-right">
            <img src={Frame1} alt="Frame1" className=""/>
            <div className="e-text">
              <h2>Unduh Mantu</h2>
              <h6>MINGGU</h6>
              <h4>15 DESEMBER</h4>
              <h6>2024</h6>
              <h6>Pukul 10:00 WIB</h6>

              <hr />

              <h6>Rumah Bpk (alm) H. M. Zaini</h6>
              <p>Gurami No. 109 Kauman Bangil</p>
            </div>
          </div>
         
      </div>
    </div>
  );
}

export default Event2;
