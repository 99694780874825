import React, {useEffect, useState} from 'react';
import OrnamentPage from '../assets/images/flower-vintage-1.png';
import OrnamentPageBottom from '../assets/images/flower-vintage-3.png';
import { initScrollReveal } from "../components/ScrollReveal";

function Thankyou() {
  useEffect(() => {
    initScrollReveal();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to toggle the modal visibility
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const [copySuccess1, setCopySuccess1] = useState('');
  const [copySuccess2, setCopySuccess2] = useState('');

  const handleCopyBri = () => {
    const textToCopy = "351101043728530";
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        setCopySuccess1('Copied!');
        setTimeout(() => {
          setCopySuccess1(''); 
        },400);
      })
      .catch((err) => {
        setCopySuccess1('Failed to copy!');
      });
  };

  const handleCopySeabank = () => {
    const textToCopy = "901276427383";
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        setCopySuccess2('Copied!');
        setTimeout(() => {
          setCopySuccess2('');
        },400);
      })
      .catch((err) => {
        setCopySuccess2('Failed to copy!');
      });
  };

  return (
    <div className="relative min-h-screen flex flex-col items-center justify-center">
      <img src={OrnamentPage} className="w-ornament-right absolute right-0 top-0 reveal-right" />
      <img src={OrnamentPage} className="w-ornament-left absolute left-0 top-0 reveal-right" />
      <img src={OrnamentPageBottom} className="w-ornament-bottom absolute bottom-0 z-10 reveal-bottom" />

      <div className="t-text reveal-zoom-in">
        <h1>Terima Kasih</h1>
        <br />
        <h3>Atas kehadiran dan do’a restu dari Bapak/Ibu/Saudara/i sekalian, kami mengucapkan Terima Kasih.</h3>
        <br />
        <h3>Kami yang berbahagia</h3>
        <h1>Allan & Ninin</h1>
      </div>

    <div className="flex gap-2 text-sm">
      <a 
        href="https://maps.app.goo.gl/9uwv1gkp24pSGTeb6" 
        target="_blank" 
        rel="noopener noreferrer"
        className="btn-invitation mx-auto text-white mt-4 reveal-left"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5 me-1 ">
          <path strokeLinecap="round" strokeLinejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z" />
        </svg>
        Buka Maps
      </a>
      {/* <button 
        onClick={toggleModal}
        className="btn-invitation mx-auto text-white mt-4 reveal-right"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5 me-1">
          <path strokeLinecap="round" strokeLinejoin="round" d="M21 11.25v8.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 1 0 9.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1 1 14.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
        </svg>
        Wedding Gift
      </button> */}
    </div>
    

      {isModalOpen && (
        <div className="fixed inset-0 flex justify-center items-end z-50">
          <div className="fixed inset-0 bg-black opacity-50" onClick={toggleModal}></div>
          <div 
            className="bg-white w-full sm:w-96 p-6 shadow-xl slide-from-bottom"
            style={{backgroundColor:'blanchedalmond'}}
          >
            <div className="flex justify-between items-center">
              <svg class="w-7 h-7 text-gray-500 dark:text-gray-400 mb-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M18 5h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C8.4.842 6.949 0 5.5 0A3.5 3.5 0 0 0 2 3.5c.003.52.123 1.033.351 1.5H2a2 2 0 0 0-2 2v3a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V7a2 2 0 0 0-2-2ZM8.058 5H5.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM11 13H9v7h2v-7Zm-4 0H2v5a2 2 0 0 0 2 2h3v-7Zm6 0v7h3a2 2 0 0 0 2-2v-5h-5Z"/>
              </svg>
              <h2 className="text-xl font-semibold">Wedding Gift</h2>
              <button onClick={toggleModal} className="font-bold">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <p className="mt-4">Doa Restu Anda merupakan karunia yang sangat berarti bagi kami. Dan jika memberi adalah ungkapan tanda kasih Anda, Anda dapat memberi kado secara cashless.</p>

            {/* <div style={{backgroundColor:'floralwhite'}} className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mt-4">
                <h5 className="font-normal text-gray-500 dark:text-gray-400">No. Rekening BTN</h5>
                <p className="text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">351101043728530</p>
                <p className="mb-2 text-xs font-semibold tracking-tight text-gray-900 dark:text-white">a/n Fitrotul Aini Zamhariri</p>
                <button onClick={handleCopyBri} type="button" className="text-white bg-gray-800 hover:bg-gray-900 font-medium rounded-full text-xs px-4 py-2.5 me-2 mb-2">
                  {copySuccess1 ? copySuccess1 : 'Copy'}
                </button>
            </div> */}

            <div style={{backgroundColor:'floralwhite'}} className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mt-4">
                <h5 className="font-normal" style={{color: '#000'}}>No. Rekening Seabank</h5>
                <p className="text-2xl font-bold tracking-tight" style={{color: '#a0525c'}}>901276427383</p>
                <p className="mb-2 text-xs font-semibold tracking-tight">a/n Fitrotul Aini Zamhariri</p>
                <button onClick={handleCopySeabank} type="button" className="text-white bg-gray-800 hover:bg-gray-900 font-medium rounded-full text-xs px-4 py-2.5 me-2 mb-2">
                  {copySuccess2 ? copySuccess2 : 'Copy'}
                </button>
            </div>

          </div>
        </div>
      )}
     
    </div>
  );
}

export default Thankyou;
